export default class extends HTMLElement {
  connectedCallback () {
    this.anchorLinks.forEach(link => {
      link.addEventListener('click', this.onAnchorClick)
    })
  }

  disconnectedCallback () {
    this.anchorLinks.forEach(link => {
      link.removeEventListener('click', this.onAnchorClick)
    })
  }

  onAnchorClick (event) {
    event.preventDefault()

    const anchorId = this.hash
    const anchorElement = document.querySelector(anchorId)

    if (anchorElement) {
      anchorElement.scrollIntoView()

      const url = new URL(window.location.href)
      url.hash = anchorId

      window.Turbo.navigator.history.replace(url, `${window.location.protocol}//${window.location.host}`)
    }
  }

  get anchorLinks () {
    return document.querySelectorAll('a[href^="#"]')
  }
}
