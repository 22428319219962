export default class extends HTMLElement {
  connectedCallback () {
    const lang = this.dataset.visibleLang || 'node_fetch'
    const codeEl = this.querySelector(`code[data-lang="${lang}"]`)

    this.clipboardEl.dataset.text = codeEl.textContent

    this.querySelectorAll('input[name="lang"]').forEach((el) => {
      el.addEventListener('change', this.onChange)
    })

    this.querySelectorAll('select[name="lang"]').forEach((el) => {
      el.addEventListener('change', this.onChange)
    })
  }

  onChange = (e) => {
    this.setLang(e.target.value)
  }

  setLang (lang) {
    this.querySelectorAll('pre').forEach((el) => el.classList.add('hidden'))

    const codeEl = this.querySelector(`code[data-lang="${lang}"]`) ||
                   this.querySelector(`code[data-lang^="${lang}_"]`)

    if (codeEl.dataset.html && !codeEl.innerHTML) {
      codeEl.innerHTML = codeEl.dataset.html
    }

    codeEl.parentElement.classList.remove('hidden')

    this.clipboardEl.dataset.text = codeEl.innerText
  }

  get clipboardEl () {
    return this.querySelector('clipboard-copy')
  }
}
