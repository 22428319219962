export default class LanguageSelector extends HTMLElement {
  connectedCallback () {
    this.selectedLanguage = null
    this.activeButton = null
    this.installCommand = null
    this.installCommandHtml = null
    this.supportedLanguages = JSON.parse(this.dataset.languages || '[]')

    const urlParams = new URLSearchParams(window.location.search)
    const urlLang = urlParams.get('lang')

    if (urlLang && this.supportedLanguages.includes(urlLang)) {
      this.selectedLanguage = urlLang
      this.activeButton = this.querySelector(`button[data-lang="${urlLang}"]`)
      this.installCommand = this.activeButton.dataset.installCommand
      this.installCommandHtml = this.activeButton.dataset.installCommandHtml

      this.updateSnippets()
      this.updateInstalCommands()
      this.updateGithub()
    }

    this.controlButtons.forEach((button) => {
      button.addEventListener('click', (e) => this.onButtonClick(e))
    })
  }

  onButtonClick (event) {
    this.activeButton = event.currentTarget
    this.selectedLanguage = this.activeButton.dataset.lang
    this.installCommand = this.activeButton.dataset.installCommand
    this.installCommandHtml = this.activeButton.dataset.installCommandHtml

    const url = new URL(window.location.href)

    url.searchParams.set('lang', this.selectedLanguage)
    window.Turbo.navigator.history.replace(url, `${window.location.protocol}//${window.location.host}`)

    this.updateSnippets()
    this.updateInstalCommands()
    this.updateGithub()
  }

  updateSnippets () {
    this.controlButtons.forEach((button) => button.classList.remove('bg-base-300'))
    this.activeButton.classList.add('bg-base-300')

    document.querySelectorAll('api-snippet').forEach((snippetElement) => {
      if (snippetElement.setLang) {
        snippetElement.setLang(this.selectedLanguage)
        snippetElement.querySelectorAll('select[name="lang"]').forEach((el) => {
          el.value = snippetElement.querySelector(`code[data-lang^="${this.selectedLanguage}_"]`).dataset.lang
        })
      }
    })
  }

  updateInstalCommands () {
    document.querySelectorAll('#api_install_cmd_body').forEach((el) => {
      el.innerHTML = this.installCommandHtml
    })

    document.querySelectorAll('#api_install_cmd_clipboard_copy').forEach((el) => {
      el.dataset.text = this.installCommand
    })
  }

  updateGithub () {
    if (this.activeButton.dataset.githubUrl) {
      document.querySelectorAll('#api_install_github_url').forEach((el) => {
        el.classList.remove('hidden')
        el.href = this.activeButton.dataset.githubUrl
      })
    } else {
      document.querySelectorAll('#api_install_github_url').forEach((el) => {
        el.classList.add('hidden')
      })
    }
  }

  get controlButtons () {
    return this.querySelectorAll('button[data-lang]')
  }
}
