export default class extends HTMLElement {
  connectedCallback () {
    const urlHash = window.location.hash.slice(1)

    if (urlHash) {
      const anchorElement = document.getElementById(urlHash)

      if (anchorElement.offsetWidth === 0 || anchorElement.offsetHeight === 0) {
        const hiddenParent = anchorElement.closest('.hidden')

        if (hiddenParent) {
          const showAllButton = hiddenParent.parentElement.querySelector('#show-all-button')

          hiddenParent.classList.remove('hidden')
          anchorElement.scrollIntoView()

          if (showAllButton) {
            showAllButton.classList.add('hidden')
          }
        }
      }
    }
  }
}
