export default class extends HTMLElement {
  static observedAttributes = [
    'data-text'
  ]

  attributeChangedCallback () {
    this.clearChecked()
  }

  connectedCallback () {
    this.clearChecked()

    if (this.dataset.urlHash) {
      if (location.hash === `#${this.dataset.urlHash}`) {
        this.classList.remove('hidden')
      }
    }

    this.addEventListener('click', (e) => {
      e.stopPropagation()

      const text = this.dataset.text || this.innerText.trim()

      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)

        if (this.dataset.urlHash) {
          history.pushState({}, '', `#${this.dataset.urlHash}`)
        }

        if (this.dataset.resetChecked === 'true') {
          setTimeout(() => { this.clearChecked() }, 1500)
        }
      } else {
        if (e.target.tagName !== 'INPUT') {
          alert(`Clipboard not available. Make sure you're using https://\nCopy text: ${text}`)
        }
      }
    })
  }

  clearChecked () {
    this.querySelectorAll('input').forEach((e) => {
      e.checked = false
    })
  }
}
